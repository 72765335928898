.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: cover;
  /* min-width: 100%;
  min-height: 100%; */
}

video#myVideo {
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  -webkit-filter: blur(0);
  filter: blur(0);
  height: 100%;
  transition: -webkit-filter 0.35s ease-in-out;
  transition: filter 0.35s ease-in-out;
  transition: filter 0.35s ease-in-out, -webkit-filter 0.35s ease-in-out;
  width: 100%;
}

.modal-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .App .ui.container {
    width: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
